var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.minimized || _vm.calls.length,
          expression: "!minimized || calls.length"
        }
      ],
      staticClass: "live-chat-app",
      on: { show: _vm.show, "create-call": _vm.createCall }
    },
    [
      _c("audio", {
        attrs: {
          id: "new-incoming-call",
          src: "/assets/holidaysplease-2012/audio/new-incoming-chat.mp3",
          loop: ""
        }
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "chat",
          class: { "live-chat-minimized": _vm.minimized }
        },
        [
          _c(
            "div",
            {
              staticClass: "header",
              on: {
                click: function($event) {
                  _vm.minimized = false
                }
              }
            },
            [
              _vm._m(1),
              _vm._v(" "),
              !_vm.minimized
                ? _c("i", {
                    staticClass: "close fas fa-times",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.close.apply(null, arguments)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.minimized
                ? _c("i", {
                    staticClass: "close fas fa-chevron-up",
                    on: {
                      click: function($event) {
                        _vm.minimized = false
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("Live Chat")])
            ]
          ),
          _vm._v(" "),
          _vm.isAgent
            ? _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.minimized,
                      expression: "!minimized"
                    }
                  ],
                  staticClass: "nav nav-tabs"
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "col-xs-12 col-sm-4",
                      class: { active: _vm.activeTab === -1 }
                    },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.changeTab(-1)
                            }
                          }
                        },
                        [
                          _vm._v("Call List\n\t\t\t\t\t\t"),
                          _vm.incomingCalls.length > 0
                            ? _c("span", { staticClass: "badge" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.incomingCalls.length) +
                                    "\n\t\t\t\t\t\t"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.activeCalls, function(call) {
                    return _c(
                      "li",
                      {
                        key: call.id,
                        staticClass: "col-xs-12 col-sm-4",
                        class: { active: _vm.activeTab === call.id },
                        attrs: { bind: call }
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.changeTab(call.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  call.from.type === "anonymous"
                                    ? "New Customer"
                                    : call.from.name
                                ) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.minimized &&
          (_vm.activeTab !== -1 ||
            (!_vm.isAgent &&
              _vm.activeCalls.length > 0 &&
              _vm.activeCalls[0].state !== "ringing"))
            ? _c("calls", { attrs: { activeTab: _vm.activeTab } })
            : _vm._e(),
          _vm._v(" "),
          _c("call-list", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.minimized && _vm.isAgent && _vm.activeTab === -1,
                expression: "!minimized && isAgent && activeTab === -1"
              }
            ],
            on: { "focus-call": _vm.changeTab }
          }),
          _vm._v(" "),
          !_vm.minimized ? _c("create-call") : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.incomingCalls.length > 0
        ? _c("div", { staticClass: "chat-overlay" }, [
            _c(
              "div",
              { attrs: { id: "new-chat-popup" } },
              _vm._l(_vm.incomingCalls, function(call) {
                return _c(
                  "div",
                  { key: call.id, staticClass: "panel panel-default" },
                  [
                    _vm._m(2, true),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "panel-body" },
                      [
                        call.clientInfo.clientData &&
                        call.clientInfo.clientData.hasOwnProperty(
                          "onlineBooking"
                        )
                          ? _c(
                              "div",
                              { staticClass: "online-booking-banner" },
                              [
                                _c("i", {
                                  staticClass: "fas fa-exclamation fa-lg"
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tOnline Booking Page – Question about " +
                                    _vm._s(
                                      call.clientInfo.clientData.onlineBooking
                                        .hotelName
                                    ) +
                                    " " +
                                    _vm._s(
                                      call.clientInfo.clientData.onlineBooking
                                        .destinationName
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                                _c("p", [
                                  _c("strong", [_vm._v("Booking Stage:")]),
                                  _vm._v(
                                    _vm._s(
                                      call.clientInfo.clientData.onlineBooking
                                        .route
                                    )
                                  ),
                                  _c("br")
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.now - call.startTime) / 1000 >= 90
                          ? _c("h2", [
                              _c("strong", [
                                _vm._v(
                                  "This customer has been waiting for " +
                                    _vm._s(
                                      Math.floor(
                                        (_vm.now - call.startTime) / 1000
                                      )
                                    ) +
                                    " seconds, please claim this chat!"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        call.enquiry
                          ? _c("p", [
                              _c("strong", [
                                _vm._v(
                                  "Claiming this chat will claim the customer enquiry."
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        call.from.type === "customer"
                          ? _c("p", [
                              _c("strong", [_vm._v("Customer Name:")]),
                              _vm._v(_vm._s(call.from.name))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _c("strong", [_vm._v("Page title:")]),
                          _vm._v(_vm._s(call.clientInfo.pageTitle)),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Page url:")]),
                          _vm._v(_vm._s(call.clientInfo.pageUrl)),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Question:")]),
                          _vm._v(_vm._s(call.question) + "\n\t\t\t\t\t\t")
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success claim-chat",
                            on: {
                              click: function($event) {
                                return _vm.claimCall(call)
                              }
                            }
                          },
                          [_vm._v("Claim")]
                        ),
                        _vm._v(" "),
                        call.clientInfo.websiteId === 15
                          ? [
                              _c("div", { staticClass: "kudos-bubble" }, [
                                _vm._v("+2 kudos")
                              ])
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        true
                          ? _c(
                              "span",
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default close-modal",
                                    on: {
                                      click: function($event) {
                                        return call.silence()
                                      }
                                    }
                                  },
                                  [_vm._v("Close")]
                                ),
                                _vm._v(" "),
                                call.clientInfo.websiteId === 15
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kudos-bubble negative-kudos"
                                        },
                                        [_vm._v("-0.25 kudos")]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("audio", { attrs: { id: "new-message" } }, [
      _c("source", {
        attrs: {
          src: "/assets/holidaysplease-2012/audio/new-message.mp3",
          type: "audio/mp3"
        }
      }),
      _vm._v(" "),
      _c("source", {
        attrs: {
          src: "/assets/holidaysplease-2012/audio/new-message.ogg",
          type: "audio/ogg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "medallion" }, [_c("div", [_vm._v("[]")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("New Live Chat")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }