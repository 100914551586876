var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message",
      class: [
        "message-" + _vm.message.type,
        "message-from-" + _vm.message.from.type
      ],
      attrs: { title: _vm.time }
    },
    [
      _vm.message.type === "join" && _vm.message.from.type === "agent"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-user" }),
            _vm._v(
              " Holidaysplease expert " +
                _vm._s(_vm.message.from.name) +
                " has joined the conversation."
            )
          ])
        : _vm.message.type === "join"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-user" }),
            _vm._v(
              " " +
                _vm._s(_vm.message.from.name) +
                " has joined the conversation."
            )
          ])
        : _vm.message.type === "text"
        ? _c("div", [_vm._v(_vm._s(_vm.message.parameters.text))])
        : _vm.message.type === "end"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-times" }),
            _vm._v(" Call has ended. "),
            _c(
              "button",
              {
                staticClass: "btn btn-danger btn-xs",
                on: { click: _vm.close }
              },
              [_vm._v("Close")]
            )
          ])
        : _vm.message.type === "leave"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-times" }),
            _vm._v(" " + _vm._s(_vm.message.from.name) + " has left the call.")
          ])
        : _vm.message.type === "connected"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-user" }),
            _vm._v(" " + _vm._s(_vm.message.from.name) + " has connected.")
          ])
        : _vm.message.type === "disconnected"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-times" }),
            _vm._v(
              " " +
                _vm._s(_vm.message.from.name) +
                " has disconnected, attempting to get them back..."
            )
          ])
        : _vm.message.type === "call"
        ? _c("div", [
            _c("i", { staticClass: "fas fa-phone" }),
            _vm._v(" Call from " + _vm._s(_vm.message.from.name) + ".")
          ])
        : _c("div", [
            _vm._v(
              _vm._s(_vm.message.type) +
                " " +
                _vm._s(_vm.message.from.name) +
                " " +
                _vm._s(_vm.message.text)
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }