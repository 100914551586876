'use strict';

class Message
{
	/**
	 * @param {String} type The message type: call, answer, text, join.
	 * @param {User} from The user sending the message.
	 * @param {Date} timestamp The date and time at which the message was sent.
	 */
	constructor(type, from, timestamp, parameters)
	{
		assert(typeof type === 'string');
		assert(timestamp instanceof Date);

		this.type = type;
		this.from = from;
		this.timestamp = timestamp;
		this.parameters = parameters;
	}
}

export default Message;
