<template>
	<div v-if="['connected', 'disconnected', 'ended'].indexOf(call.state) !== -1" class="call">
		<div class="call-top-tools">
			<div class="live-chat-question" :title="call.question">{{ call.question }}</div>
			<button v-if="call.state !== 'ended'" class="btn btn-default btn-xs pull-right" @click="call.end()">End Call</button>
		</div>

		<div class="messages">
			<div class="call-info">
				<i class="fas fa-question-circle"></i> {{ call.question }}
				<div v-if="call.incoming && call.from.type === 'customer'"><i class="fas fa-user"></i> {{ call.from.name }}</div>
				<div v-if="call.enquiry && call.incoming">
					<div><b>Enquiry {{ call.enquiry.id }}</b></div>
					<div v-if="call.enquiry.destinationText"><i class="fas fa-plane"></i> {{ call.enquiry.destinationText }}</div>
					<i v-if="call.enquiry.extraInfo && !showExtra" class="fas fa-chevron-down" @click="showExtra = true" title="Show more..."></i>
					<div v-if="showExtra"><i class="fas fa-comment"></i> {{ call.enquiry.extraInfo }}</div>
					<i v-if="showExtra" class="fas fa-chevron-up" @click="showExtra = false" title="Show Less..."></i>
				</div>
			</div>

			<message v-for="(message, index) in call.messages" :key="index" :message="message" v-on:close="call.close()" />

		</div>

		<div class="send-bar">
			<div class="users-typing">
				<div v-if="call.usersTyping.length">
					{{ call.usersTyping.map(user => user.name).join(', ') }} {{ call.usersTyping.length === 1 ? 'is' : 'are'}} typing...
				</div>
			</div>
			<input type="text" v-model="inputText" placeholder="Enter your message..." @keyup.enter="send" @keydown="typing"><button @click="send">Send</button>
		</div>
	</div>
</template>

<script>
import Message from './Message';
import {play} from './audio';

export default {
	props: ['call'],
	components: { Message },
	data: function ()
	{
		return {
			inputText: '',
			showExtra: false,
			typingTimer: null,
		};
	},
	computed: {
		messageList()
		{
			return this.call.messages;
		},
	},
	methods: {
		send()
		{
			if (this.typingTimer)
			{
				clearTimeout(this.typingTimer);
				this.typingTimer = null;
				this.$root.client.send('typing', this.call.id, false);
			}

			if (!this.inputText)
				return;

			console.log('Call::send(): Sending message ' + this.inputText);
			this.$root.client.send('say', this.call.id, this.inputText);
			this.inputText = '';
		},

		/**
		 * Scrolls to the bottom of the messages.
		 */
		scrollToBottom()
		{
			// Run in the next tick so the document has updated with the new content.
			this.$nextTick(() =>
			{
				// The $el property is a string when the element is being deleted.
				if (!this.$el.getElementsByClassName)
					return;

				const messagesContainer = this.$el.getElementsByClassName('messages')[0];
				messagesContainer.scrollTop = messagesContainer.scrollHeight;
			}, 0);
		},

		/**
		 * Notify the server that a user is typing.
		 */
		typing()
		{
			if (this.typingTimer)
				clearTimeout(this.typingTimer);
			else
				this.$root.client.send('typing', this.call.id, true);

			// Reset the timeout.
			this.typingTimer = setTimeout(() => { this.$root.client.send('typing', this.call.id, false); this.typingTimer = null; }, 5000);
		}
	},

	mounted()
	{
		this.scrollToBottom();
	},

	watch: {
		messageList()
		{
			play(document.getElementById('new-message'));

			this.scrollToBottom();
		},
	}
}
</script>

<style lang="scss">
	@import 'variables.scss';

	.call
	{
		height: 100%;
		display: flex;
		flex-direction: column;

		.call-top-tools
		{
			background-color: $hpGreen;
			color: white;
			padding: 5px;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 5px;
			z-index: 1;

			.live-chat-question
			{
				font-style: italic;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.messages
		{
			overflow-y: auto;
			flex: 1;
			position: relative;

			.message,
			.call-info
			{
				background: $hpGreen;
				margin: 10px;
				border-radius: 10px;
				max-width: 70%;
				margin-right: auto;
				padding: 10px;
				color: white;
			}

			.message-from-agent
			{
				margin-right: 10px;
				margin-left: auto;
				background: white;
				border: 1px solid $hpGreen;
				color: black;
			}

			.call-info
			{
				max-width: 100%;
			}
		}

		.users-typing
		{
			height: 18px;
			font-style: italic;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: black;
			background: white;
			opacity: 0.7;
			border-radius: 5px;
			position: absolute;
			top: -20px;
			margin: 2px;
		}
	}

	.send-bar
	{
		background: $hpGreen;
		color: white;
		height: 50px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		padding: 10px;
		position: relative;

		input,
		button
		{
			border: 0px;
			margin: 0px;
			height: 30px;
		}

		input
		{
			border-radius: 5px 0px 0px 5px;
			width: calc(100% - 50px);
			padding-left: 5px;
			color: black;
		}

		button
		{
			width: 50px;
			background: $hpGreenLight;
			color: white;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}
</style>
