var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return ["connected", "disconnected", "ended"].indexOf(_vm.call.state) !== -1
    ? _c("div", { staticClass: "call" }, [
        _c("div", { staticClass: "call-top-tools" }, [
          _c(
            "div",
            {
              staticClass: "live-chat-question",
              attrs: { title: _vm.call.question }
            },
            [_vm._v(_vm._s(_vm.call.question))]
          ),
          _vm._v(" "),
          _vm.call.state !== "ended"
            ? _c(
                "button",
                {
                  staticClass: "btn btn-default btn-xs pull-right",
                  on: {
                    click: function($event) {
                      return _vm.call.end()
                    }
                  }
                },
                [_vm._v("End Call")]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "messages" },
          [
            _c("div", { staticClass: "call-info" }, [
              _c("i", { staticClass: "fas fa-question-circle" }),
              _vm._v(" " + _vm._s(_vm.call.question) + "\n\t\t\t"),
              _vm.call.incoming && _vm.call.from.type === "customer"
                ? _c("div", [
                    _c("i", { staticClass: "fas fa-user" }),
                    _vm._v(" " + _vm._s(_vm.call.from.name))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.call.enquiry && _vm.call.incoming
                ? _c("div", [
                    _c("div", [
                      _c("b", [
                        _vm._v("Enquiry " + _vm._s(_vm.call.enquiry.id))
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.call.enquiry.destinationText
                      ? _c("div", [
                          _c("i", { staticClass: "fas fa-plane" }),
                          _vm._v(" " + _vm._s(_vm.call.enquiry.destinationText))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.call.enquiry.extraInfo && !_vm.showExtra
                      ? _c("i", {
                          staticClass: "fas fa-chevron-down",
                          attrs: { title: "Show more..." },
                          on: {
                            click: function($event) {
                              _vm.showExtra = true
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showExtra
                      ? _c("div", [
                          _c("i", { staticClass: "fas fa-comment" }),
                          _vm._v(" " + _vm._s(_vm.call.enquiry.extraInfo))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showExtra
                      ? _c("i", {
                          staticClass: "fas fa-chevron-up",
                          attrs: { title: "Show Less..." },
                          on: {
                            click: function($event) {
                              _vm.showExtra = false
                            }
                          }
                        })
                      : _vm._e()
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm._l(_vm.call.messages, function(message, index) {
              return _c("message", {
                key: index,
                attrs: { message: message },
                on: {
                  close: function($event) {
                    return _vm.call.close()
                  }
                }
              })
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "send-bar" }, [
          _c("div", { staticClass: "users-typing" }, [
            _vm.call.usersTyping.length
              ? _c("div", [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        _vm.call.usersTyping
                          .map(function(user) {
                            return user.name
                          })
                          .join(", ")
                      ) +
                      " " +
                      _vm._s(_vm.call.usersTyping.length === 1 ? "is" : "are") +
                      " typing...\n\t\t\t"
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputText,
                expression: "inputText"
              }
            ],
            attrs: { type: "text", placeholder: "Enter your message..." },
            domProps: { value: _vm.inputText },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.send.apply(null, arguments)
              },
              keydown: _vm.typing,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputText = $event.target.value
              }
            }
          }),
          _c("button", { on: { click: _vm.send } }, [_vm._v("Send")])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }