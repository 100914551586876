import Vue from 'vue';
import LiveChat from './LiveChat';
import Client from './Core/Client';

// Polyfill the assert() function.
if (typeof window.assert === 'undefined')
{
	window.assert = function (condition, message)
	{
		if (!condition)
			throw new Error(message ? message : 'Assertion failed');
	}
}

/**
 * Create the live chat Vue application.
 *
 * The Vue application should be added to the page with
 * `LiveChatController::liveChat()`.
 */
function initialiseLiveChat()
{
	// Check that socket.io has been loaded.  If not, the server is not running.
	if (typeof io === 'undefined')
	{
		console.error('Live Chat: Socket.io has not been loaded');
		return;
	}

	// Get the user's encrypted session ID.
	const options = JSON.parse(document.getElementById('live-chat').dataset.liveChatOptions);

	// Create the chat client protocol handler.
	const client = new Client(io, options);

	// Create the Vue application.
	new Vue({
		el: '#live-chat',
		components: { LiveChat },
		data: { client, activeTab: -1 },
		render: (h) => h(LiveChat)
	});
}

document.addEventListener('DOMContentLoaded', initialiseLiveChat);
