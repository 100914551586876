<template>
	<div class="calls">
		<call v-for="call in activeCalls" v-bind:key="call.id" v-bind:call="call" v-if="activeTab === call.id || !isAgent()" />
		<p v-if="$root.client.calls.length === 0">There are no calls in progress</p>
	</div>
</template>

<script>
import Call from './Call';

export default {
	components: { Call },

	props: ['activeTab'],

	computed: {
		/**
		 * Finds all active calls for the user.
		 *
		 * @return {Array} The filtered array of active calls.
		 */
		activeCalls()
		{
			return this.$root.client.calls.filter(call => {
				return ['connected', 'disconnected', 'ended'].includes(call.state) || call.from.id === this.$root.client.account.id;
			});
		}

	},

	methods: {
		/**
		 * Whether the current client user is an agent.
		 *
		 * @return {boolean} Whether the current user is an agent.
		 */
		isAgent()
		{
			return !!this.$root.client.account && this.$root.client.account.type === 'agent';
		},
	}
}
</script>

<style lang="scss">
	.calls {
		overflow-y: auto;
		overflow-x: hidden;
		flex: 1;
	}
</style>
