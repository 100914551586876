var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calls" },
    [
      _vm._l(_vm.activeCalls, function(call) {
        return _vm.activeTab === call.id || !_vm.isAgent()
          ? _c("call", { key: call.id, attrs: { call: call } })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.$root.client.calls.length === 0
        ? _c("p", [_vm._v("There are no calls in progress")])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }