'use strict';

import Message from './Message';

class Call
{
	/**
	 * A call.
	 *
	 * @param {Client} client The chat client.
	 * @param {String} id The call's unique ID.
	 * @param {Date} startTime The timestamp that the call was started.
	 * @param {String} state The state of the call, e.g. 'ringing'.
	 * @param {User} user The user that created the call.
	 * @param {String} question The subject of the call.
	 * @param {Message[]} messages Messages in the call.
	 * @param {Object} enquiry The enquiry that will be claimed along with the call, or null.
	 * @param {ClientInfo} clientInfo Information about the client that placed the call.
	 */
	constructor(client, id, startTime, state, user, question, messages, enquiry, clientInfo)
	{
		assert(Object.values(Call).indexOf(state) !== -1, 'Invalid state ' + state);
		assert(startTime instanceof Date);
		assert(messages instanceof Array);

		this.client = client;
		this.id = id;
		this.startTime = startTime;
		this.usersTyping = [];
		this.state = state;
		this.from = user;
		this.incoming = !(client.account.type === user.type && client.account.id === user.id);
		this.question = question;
		this.messages = messages;
		this.enquiry = enquiry;
		this.clientInfo = clientInfo;
		console.log('Created call:', id, user, question, messages, enquiry, clientInfo);
	}

	/**
	 * Answer the call.
	 */
	answer()
	{
		if (this.state === Call.RINGING || this.state === Call.SILENCED)
		{
			this.state = Call.CONNECTED;
			this.client.send('answer', this.id);
		}
	}

	/**
	 * Stop the call from ringing.
	 */
	silence()
	{
		if (this.state === Call.RINGING)
		{
			this.state = Call.SILENCED;
			this.client.send('silence', this.id);
		}
	}

	/**
	 * Hang up the call.
	 */
	end()
	{
		this.client.send('end', this.id);
		this.state = Call.ENDED;
	}

	/**
	 * Close the call window.
	 */
	close()
	{
		this.client.send('close', this.id);
	}

	/**
	 * Add a message to the transcript.
	 *
	 * @param {Message} message The message to add to the transcript.
	 */
	addMessage(message)
	{
		assert(message instanceof Message);
		assert(typeof message.from !== 'undefined');

		this.messages.push(message);
	}

	/**
	 * Set the typing status of a user.
	 *
	 * @param {User} user The user starting/stopping typing.
	 * @param {Boolean} status True if the user is typing, false if they are not.
	 */
	typing(user, status)
	{
		if (status)
			this.usersTyping.push(user);
		else
			this.usersTyping = this.usersTyping.filter(element => element.type !== user.type || element.id !== user.id);
	}
}

Call.RINGING = 'ringing';
Call.SILENCED = 'silenced';
Call.CONNECTED = 'connected';
Call.ENDED = 'ended';
Call.CLOSED = 'closed';

export default Call;
