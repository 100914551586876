var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    !(
      this.$root.client.calls.length ||
      (this.$root.client.account && this.$root.client.account.type === "agent")
    )
      ? _c("div", { staticClass: "send-bar" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.question,
                expression: "question"
              }
            ],
            attrs: { type: "text", placeholder: "Ask a question..." },
            domProps: { value: _vm.question },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.createCall.apply(null, arguments)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.question = $event.target.value
              }
            }
          }),
          _c(
            "button",
            {
              attrs: { disabled: !_vm.question },
              on: { click: _vm.createCall }
            },
            [_vm._v("Ask")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.calling
      ? _c("div", { staticClass: "calling" }, [
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("i", { staticClass: "fas fa-spinner fa-pulse" }),
          _c("br"),
          _c("br"),
          _vm._v("\n\t\tCalling an Agent..."),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-danger", on: { click: _vm.cancel } },
            [_c("i", { staticClass: "fas fa-phone-slash" }), _vm._v(" Cancel")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }