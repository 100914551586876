<template>
	<div class="calls call-list">
		<call-list-item v-for="call in $root.client.calls" :key="call.id" :call="call" v-on:focus-call="id => $emit('focus-call', id)"/>
		<div v-if="$root.client.calls.length === 0" class="call-list-item"><i class="fas fas-circle"></i> There are currently no calls.</div>
	</div>
</template>

<script>
import CallListItem from './CallListItem';
import {play} from './audio';

export default {
	computed: {
		callsRinging()
		{
			return !!this.$root.client.calls.filter(call => call.state === 'ringing' && call.incoming).length;
		}
	},
	components: { CallListItem },
	watch: {
		callsRinging: function ()
		{
			console.log('Calls Ringing: ' + this.callsRinging);

			const audio = document.getElementById('new-incoming-call');

			if (this.callsRinging)
			{
				play(audio);
			}
			else
			{
				audio.pause();
				audio.currentTime = 0;
			}
		}
	},
}
</script>
