<template>
	<div class="">
		<div v-if="!(this.$root.client.calls.length || this.$root.client.account && this.$root.client.account.type === 'agent')" class="send-bar">
			<input type="text" v-model="question" placeholder="Ask a question..." @keyup.enter="createCall"><button @click="createCall" :disabled="!question">Ask</button>
		</div>
		<div v-if="calling" class="calling">
			<br><br>
			<i class="fas fa-spinner fa-pulse"></i><br><br>
			Calling an Agent...<br><br>
			<button class="btn btn-danger" @click="cancel"><i class="fas fa-phone-slash"></i> Cancel</button>
		</div>
	</div>
</template>

<script>
import Message from './Message';

export default {
	props: ['call'],
	data: function ()
	{
		return { question: '' };
	},
	computed: {
		/**
		 * Get whether there is an unanswered outgoing call.
		 *
		 * @return {Boolean} Returns true if there is an outgoing call ringing.
		 */
		calling()
		{
			return !!this.$root.client.calls.filter(call => !call.incoming && call.state === 'ringing').length;
		}
	},
	methods: {
		/**
		 * Create a new outgoing call.
		 */
		createCall()
		{
			this.$parent.details ?
				this.$root.client.createCall(null, this.question, this.$parent.details) :
				this.$root.client.createCall(null, this.question)
		},

		/**
		 * Cancel the outgoing call.
		 */
		cancel()
		{
			for (const call of this.$root.client.calls)
			{
				if (!call.incoming && call.state === 'ringing')
					this.$root.client.closeCall(call);
			}
		}
	}
}
</script>

<style lang="scss">
.calling
{
	text-align: center;
	font-size: 120%;

	.fa-spinner
	{
		font-size: 50px;
	}
}
</style>
