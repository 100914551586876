var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "call-list-item",
      on: {
        click: function($event) {
          return _vm.$emit("focus-call", _vm.call.id)
        }
      }
    },
    [
      _vm.call.state === "silenced"
        ? _c("i", { staticClass: "fas fa-phone-slash" })
        : _vm._e(),
      _vm._v(" "),
      _vm.call.state === "ringing"
        ? _c("i", { staticClass: "fas fa-phone-volume" })
        : _vm._e(),
      _vm._v(" "),
      _vm.call.state === "connected"
        ? _c("i", { staticClass: "fas fa-phone" })
        : _vm._e(),
      _vm._v(" "),
      _vm.call.state === "ended"
        ? _c("i", { staticClass: "fas fa-phone-slash" })
        : _vm._e(),
      _vm._v(" "),
      _vm.call.incoming
        ? _c("span", [
            _vm._v(
              "Call from " +
                _vm._s(
                  _vm.call.type === "anonymous"
                    ? "New Customer"
                    : _vm.call.from.name
                ) +
                "."
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "live-chat-question",
          attrs: { title: _vm.call.question }
        },
        [_vm._v(_vm._s(_vm.call.question))]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "call-list-actions" }, [
        _vm.call.incoming && _vm.call.state === "ringing"
          ? _c(
              "button",
              {
                staticClass: "btn btn-default btn-xs",
                on: {
                  click: function($event) {
                    return _vm.call.silence()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-phone-slash" }),
                _vm._v(" Silence\n\t\t")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.call.incoming &&
        (_vm.call.state === "ringing" || _vm.call.state === "silenced")
          ? _c(
              "button",
              {
                staticClass: "btn btn-success btn-xs",
                on: {
                  click: function($event) {
                    return _vm.call.answer()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-phone" }),
                _vm._v(" Answer\n\t\t")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.call.state === "connected"
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger btn-xs",
                on: {
                  click: function($event) {
                    return _vm.call.end()
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-phone-slash" }),
                _vm._v(" Hang Up\n\t\t")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.call.incoming && _vm.call.state === "ringing"
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger btn-xs",
                on: {
                  click: function($event) {
                    return _vm.$root.client.closeCall(_vm.call)
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-phone-slash" }),
                _vm._v(" Cancel\n\t\t")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.call.state === "ended"
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger btn-xs",
                on: {
                  click: function($event) {
                    return _vm.$root.client.closeCall(_vm.call)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-times" }), _vm._v(" Close\n\t\t")]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }