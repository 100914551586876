'use strict';

import Call from './Call';
import Message from './Message';

class Client
{
	/**
	 * Create the chat client that communicates with the server
	 */
	constructor(io, options)
	{
		this.log = function (method, ...args) { console.log('Client::' + method + '():', ...args); };

		this.io = io;
		this.connected = false;
		this.agentCount = 0;
		this.account = null;
		this.options = options;

		// {Call[]} calls Array of calls in progress or ringing.
		this.calls = [];

		// {Socket} socket The socket.io socket.
		this.socket = this.io('https://' + options.server + ':3000');

		const socketEvents = [
			['connect', 'onConnect'],
			['call', 'onCall'],
			['answered', 'onAnswered'],
			['silenced', 'onSilenced'],
			['message', 'onMessage'],
			['authenticated', 'onAuthenticated'],
			['userlist', 'onUserlist'],
			['agentcount', 'onAgentcount'],
			['end', 'onEnd'],
			['close', 'onClose'],
			['typing', 'onTyping'],
			['disconnect', 'onDisconnect'],
			['error', 'onError'],
			['join', 'onJoin'],
			['leave', 'onLeave'],
			['disconnected', 'onDisconnected'],
			['connected', 'onConnected'],
		];

		for (const socketEvent of socketEvents)
			this.socket.on(socketEvent[0], this[socketEvent[1]].bind(this));
	}

	/**
	 * Send a message to the server.
	 *
	 * @param {String} message The message to send.
	 * @param  {...any} params Any additional message parameters.
	 */
	send(message, ...params)
	{
		this.log('send', 'Sending ' + message, ...params);
		this.socket.emit(message, ...params);
	}

	/**
	 * Event handler for the socket connecting to the server.
	 */
	onConnect()
	{
		this.log('onConnect', 'Socket connected');
		this.connected = true;
		this.log('onConnect', 'Authenticating with ' + this.options.organisation + ' using session key', this.options.sessionKey);
		this.socket.emit('authenticate', 'phpSessionKey', Object.assign({ organisation: this.options.organisation }, this.options.sessionKey));
	}

	/**
	 * Event handler for the socket being disconnected.
	 */
	onDisconnect()
	{
		this.log('onDisconnect', 'Socket disconnected');
		this.connected = false;
		this.calls = [];
	}

	/**
	 * Event handler for receiving text on a call.
	 *
	 * @param {String} callId The ID of the call receiving the message.
	 * @param {User} sender The user sending the message, or null for the system.
	 * @param {String} msg The text of the message
	 */
	onMessage(callId, message)
	{
		this.log('onMessage', 'Received a message for call ' + callId, message);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onMessage', 'Could not find call ' + callId);
			return;
		}

		this.log('onMessage', message);
		call.addMessage(new Message(message.type, message.from, new Date(message.timestamp), message.parameters));
	}

	/**
	 * Event handler for the user becoming authenticated.
	 *
	 * @param {Boolean} success True if authentication was successful, false if there was an error.
	 * @param {User} account The user's account.
	 */
	onAuthenticated(success, account)
	{
		this.log('onAuthenticated', 'Authenticated: ' + success, account);

		if (!success)
		{
			this.log('onAuthenticated', 'Authentication failed, disconnecting from server');
			this.socket.disconnect();
			return;
		}

		this.account = account;
		this.socket.emit('agentcount');
	}

	/**
	 * Event handler for the user starter/stopping typing.
	 *
	 * @param {String} callId The ID of the call.
	 * @param {Boolean} state True if the user is typing, false if they are not.
	 */
	onTyping(callId, user, state)
	{
		this.log('onTyping', 'User has ' + (state ? 'started' : 'stopped') + ' typing in call ' + callId);
		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onTyping', 'Could not find call ' + callId);
			return;
		}

		call.typing(user, state);
	}

	/**
	 * Event handler for a socket error.
	 *
	 * @param {} err The error.
	 */
	onError(err)
	{
		this.log('onError', 'Socket error', err);
	}

	/**
	 * Event handler for receiving the number of agents available.
	 *
	 * @param {Number} count The number of agents currently connected.
	 */
	onAgentcount(count)
	{
		this.log('onAgentCount', 'Got agent count: ' + count);
		this.agentCount = count;
	}

	/**
	 * Event handler for receiving a call.
	 * This may be an incoming call, or an outgoing call
	 * created by the user in another window.
	 *
	 * @param {CallInfo} callInfo The call.
	 */
	onCall(callInfo)
	{
		this.log('onCall', 'Incoming call', callInfo.id);
		const messages = callInfo.messages.map(message => new Message(message.type, message.from, new Date(message.timestamp), message.parameters));
		const call = new Call(this, callInfo.id, new Date(callInfo.startTime), callInfo.state, callInfo.from, callInfo.question, messages, callInfo.enquiry, callInfo.clientInfo);
		this.calls.push(call);
	}

	/**
	 * Silence a ringing call.
	 *
	 * @param {String} callId The ID of the call.
	 */
	onSilenced(callId)
	{
		this.log('onSilenced', 'Silencing call ' + callId);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onSilenced', 'Could not find call ' + callId);
			return;
		}

		if (call.state === Call.RINGING)
			call.state = Call.SILENCED;

		// Ring again if not answered after being silenced for too long.
		setTimeout(() =>
		{
			if (call.state === Call.SILENCED)
			{
				this.log('onSilenced', 'Call ' + call.id + ' has been silenced for too long, ringing again');
				call.state = Call.RINGING;
			}
		}, 90000);
	}

	/**
	 * Event handler for the client answering a call.
	 *
	 * @param {String} callId The call that was answered.
	 */
	onAnswered(callId, messages)
	{
		this.log('onAnswered', 'Call answered: ' + callId);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onAnswered', 'Could not find call ' + callId);
			return;
		}

		call.state = Call.CONNECTED;

		call.messages = messages.map(message => new Message(message.type, message.from, new Date(message.timestamp), message.parameters));
	}

	/**
	 * Event handler for a user joining a call.
	 *
	 * @param {String} callId The call that the user has joined.
	 * @param {User} user The user that has joined.
	 */
	onJoin(callId, user)
	{
		this.log('onJoin', 'User joined call', callId, user);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onJoin', 'Could not find call ' + callId);
			return;
		}

		call.addMessage(new Message('join', user, new Date));

		if (call.state === Call.RINGING)
			call.state = Call.CONNECTED;
	}

	/**
	 * Event handler for a user leaving a call.
	 *
	 * @param {String} callId The call that the user has left.
	 * @param {User} user The user that has left.
	 */
	onLeave(callId, user)
	{
		this.log('onLeave', 'User left call', callId, user);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onLeave', 'Could not find call ' + callId);
			return;
		}

		call.typing(user, false);
		call.addMessage(new Message('leave', user, new Date));
	}

	/**
	 * Event handler for a user connecting.
	 *
	 * @param {String} callId The call that the user has connected to.
	 * @param {User} user The user that has connected.
	 */
	onConnected(callId, user)
	{
		this.log('onConnected', 'User reconnected to call', callId, user);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onConnected', 'Could not find call ' + callId);
			return;
		}

		call.addMessage(new Message('connected', user, new Date));
	}

	/**
	 * Event handler for a user disconnecting.
	 *
	 * @param {String} callId The call that the user has disconnected from.
	 * @param {User} user The user that has disconnected.
	 */
	onDisconnected(callId, user)
	{
		this.log('onDisconnected', 'User disconnected from call', callId, user);

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onDisconnected', 'Could not find call ' + callId);
			return;
		}

		call.typing(user, false);
		call.addMessage(new Message('disconnected', user, new Date));
	}

	/**
	 * Event handler for receiving a list of all users on the server.
	 *
	 * @param {User[]} users An array of user details.
	 */
	onUserlist(users)
	{
		this.log('onUserlist', 'Received user list');
		this.userList = users;
	}

	/**
	 * Event handler for a call ending.
	 *
	 * @param {String} callId The ID of the call being ended.
	 * @param {User} user The user ending the call.
	 */
	onEnd(callId, user)
	{
		this.log('onEnd', 'Call ' + callId + ' has ended');

		const call = this.findCall(callId);

		if (!call)
		{
			this.log('onEnd', 'Could not find call ' + callId);
			return;
		}

		call.typing(user, false);
		call.addMessage(new Message('end', user, new Date));

		call.state = Call.ENDED;
	}

	/**
	 * Event handler for closing a call window.
	 *
	 * @param {String} callId
	 */
	onClose(callId)
	{
		this.log('onClose', 'Call ' + callId + ' has been closed');

		const closedCall = this.findCall(callId);

		if (!closedCall)
		{
			this.log('Could not find call ' + callId);
			return;
		}

		this.calls = this.calls.filter(call => call !== closedCall);
	}

	/**
	 * Look up a call by ID.
	 *
	 * @param {String} callId The ID of the call to locate.
	 *
	 * @return {Call} Returns the call, or null if it was not found.
	 */
	findCall(callId)
	{
		for (const call of this.calls)
		{
			if (call.id === callId)
				return call;
		}

		return null;
	}

	/**
	 * Place a call.
	 *
	 * @param {User} to The user to call, or null to call all agents.
	 * @param {String} question The subject of the call.
	 * @param {*} data Optional custom client data.
	 */
	createCall(to, question, data = null)
	{
		this.log('createCall', 'Placing call');
		this.send('call', to ? to : null, question, Object.assign(this.options, {clientData: data}));
	}

	/**
	 * Close the call window.
	 *
	 * This should be used instead of call.close() so that the call
	 * is removed from the list of calls.
	 *
	 * @param {Call} call The call to close.
	 */
	closeCall(closedCall)
	{
		closedCall.close();
		this.calls = this.calls.filter(call => call !== closedCall);
	}
}

export default Client;
