<template>
	<div class="call-list-item" @click="$emit('focus-call', call.id)">
		<i v-if="call.state === 'silenced'" class="fas fa-phone-slash"></i>
		<i v-if="call.state === 'ringing'" class="fas fa-phone-volume"></i>
		<i v-if="call.state === 'connected'" class="fas fa-phone"></i>
		<i v-if="call.state === 'ended'" class="fas fa-phone-slash"></i>

		<span v-if="call.incoming">Call from {{ call.type === 'anonymous' ? 'New Customer' : call.from.name }}.</span>

		<div class="live-chat-question" :title="call.question">{{ call.question }}</div>

		<div class="call-list-actions">
			<button class="btn btn-default btn-xs" v-if="call.incoming && call.state === 'ringing'" v-on:click="call.silence()">
				<i class="fas fa-phone-slash"></i> Silence
			</button>
			<button class="btn btn-success btn-xs" v-if="call.incoming && (call.state === 'ringing' || call.state === 'silenced')" v-on:click="call.answer()">
				<i class="fas fa-phone"></i> Answer
			</button>
			<button class="btn btn-danger btn-xs" v-if="call.state === 'connected'" v-on:click="call.end()">
				<i class="fas fa-phone-slash"></i> Hang Up
			</button>
			<button class="btn btn-danger btn-xs" v-if="!call.incoming && call.state === 'ringing'" v-on:click="$root.client.closeCall(call)">
				<i class="fas fa-phone-slash"></i> Cancel
			</button>
			<button class="btn btn-danger btn-xs" v-if="call.state === 'ended'" v-on:click="$root.client.closeCall(call)">
				<i class="fas fa-times"></i> Close
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: ['call'],

	watch: {
		'call.state': function (newState, oldState)
		{
			if (oldState === 'ringing' && newState === 'connected')
				this.$root.activeTab = this.call.id;
		}
	}
}
</script>

<style lang="scss">
.call-list-item
{
	margin: 4px;
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.6);
	padding: 4px;
	cursor: pointer;

	&:hover
	{
		background-color: rgba(255, 255, 255, 0.8);
	}

	.live-chat-question
	{
		font-style: italic;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.call-list-actions
	{
		text-align: right;
	}
}
</style>