<template>
	<div class="message" :class="['message-' + message.type, 'message-from-' + message.from.type]" :title="time">
		<div v-if="message.type === 'join' && message.from.type === 'agent'"><i class="fas fa-user"></i> Holidaysplease expert {{ message.from.name }} has joined the conversation.</div>
		<div v-else-if="message.type === 'join'"><i class="fas fa-user"></i> {{ message.from.name }} has joined the conversation.</div>
		<div v-else-if="message.type === 'text'">{{ message.parameters.text }}</div>
		<div v-else-if="message.type === 'end'"><i class="fas fa-times"></i> Call has ended. <button @click="close" class="btn btn-danger btn-xs">Close</button></div>
		<div v-else-if="message.type === 'leave'"><i class="fas fa-times"></i> {{ message.from.name }} has left the call.</div>
		<div v-else-if="message.type === 'connected'"><i class="fas fa-user"></i> {{ message.from.name }} has connected.</div>
		<div v-else-if="message.type === 'disconnected'"><i class="fas fa-times"></i> {{ message.from.name }} has disconnected, attempting to get them back...</div>
		<div v-else-if="message.type === 'call'"><i class="fas fa-phone"></i> Call from {{ message.from.name }}.</div>
		<div v-else>{{ message.type }} {{ message.from.name }} {{ message.text }}</div>
	</div>
</template>

<script>
export default {
	props: ['message'],
	computed: {
		time()
		{
			const time = this.message.timestamp;
			return time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();
		},
	},
	methods: {
		close()
		{
			this.$emit('close');
		},
	}
}
</script>
