<template>
	<div class="live-chat-app" v-show="!minimized || calls.length" v-on:show="show" v-on:create-call="createCall">

		<audio id="new-incoming-call" src="/assets/holidaysplease-2012/audio/new-incoming-chat.mp3" loop></audio>

		<audio id="new-message">
			<source src="/assets/holidaysplease-2012/audio/new-message.mp3" type="audio/mp3">
			<source src="/assets/holidaysplease-2012/audio/new-message.ogg" type="audio/ogg">
		</audio>

		<div class="chat" :class="{ 'live-chat-minimized': minimized }">
			<div class="header" @click="minimized = false">
				<div class="medallion"><div>[]</div></div>
				<i v-if="!minimized" class="close fas fa-times" @click.stop="close"></i>
				<i v-if="minimized" class="close fas fa-chevron-up" @click="minimized = false"></i>
				<div class="title">Live Chat</div>
			</div>

			<ul v-show="!minimized" class="nav nav-tabs" v-if="isAgent">
				<li class="col-xs-12 col-sm-4" :class="{ 'active': activeTab === -1 }">
					<a @click="changeTab(-1)">Call List
						<span class="badge" v-if="incomingCalls.length > 0">
							{{ incomingCalls.length }}
						</span>
					</a>
				</li>
				<li class="col-xs-12 col-sm-4" :class="{ 'active': activeTab === call.id }" v-for="call in activeCalls" :bind="call" :key="call.id">
					<a @click="changeTab(call.id)">
						{{ (call.from.type === 'anonymous' ? 'New Customer' : call.from.name) }}
					</a>
				</li>
			</ul>

			<calls v-if="!minimized && (activeTab !== -1 || (!isAgent && activeCalls.length > 0 && activeCalls[0].state !== 'ringing'))" :activeTab="activeTab" />
			<call-list v-show="!minimized && isAgent && activeTab === -1" v-on:focus-call="changeTab"></call-list>
			<create-call v-if="!minimized" />

		</div>

		<div class="chat-overlay" v-if="incomingCalls.length > 0">
			<div id="new-chat-popup">
				<div class="panel panel-default" v-for="call in incomingCalls" :key="call.id">
					<div class="panel-heading">
						<h3 class="panel-title">New Live Chat</h3>
					</div>
					<div class="panel-body">
						<div v-if="call.clientInfo.clientData && call.clientInfo.clientData.hasOwnProperty('onlineBooking')" class="online-booking-banner">
							<i class="fas fa-exclamation fa-lg"></i>
							Online Booking Page – Question about {{call.clientInfo.clientData.onlineBooking.hotelName}} {{call.clientInfo.clientData.onlineBooking.destinationName}}
							<p>
								<strong>Booking Stage:</strong>{{ call.clientInfo.clientData.onlineBooking.route }}<br>
							</p>
						</div>
						<h2 v-if="(now - call.startTime) / 1000 >= 90"><strong>This customer has been waiting for {{ Math.floor((now - call.startTime) / 1000) }} seconds, please claim this chat!</strong></h2>

						<p v-if="call.enquiry"><strong>Claiming this chat will claim the customer enquiry.</strong></p>
						<p v-if="call.from.type === 'customer'"><strong>Customer Name:</strong>{{ call.from.name}}</p>
<!--						<p v-if="newFlashMessage.ownerName && !yourCustomer()"><strong>Owned By:</strong>{{ newFlashMessage.ownerName }}</p>-->
						<p>
							<strong>Page title:</strong>{{ call.clientInfo.pageTitle }}<br>
							<strong>Page url:</strong>{{ call.clientInfo.pageUrl }}<br>
							<strong>Question:</strong>{{ call.question }}
						</p>
						<button @click="claimCall(call)" class="btn btn-success claim-chat">Claim</button>
						<template v-if="call.clientInfo.websiteId === 15">
							<div class="kudos-bubble">+2 kudos</div>
						</template>
						<span v-if="true"> <!-- TODO: yourCustomer -->
							<button @click="call.silence()" class="btn btn-default close-modal">Close</button>
							<template v-if="call.clientInfo.websiteId === 15">
								<div class="kudos-bubble negative-kudos">-0.25 kudos</div>
							</template>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CallList from './CallList';
	import Calls from './Calls';
	import CreateCall from './CreateCall';

	export default {
	components: { CallList, Calls, CreateCall },

	data()
	{
		return {
			now: new Date,
			minimized: true,
			details: null,
		};
	},

	computed: {
		/**
		 * Finds all the incoming calls.
		 *
		 * @return {Array} The filtered array of incoming calls.
		 */
		incomingCalls()
		{
			return this.$root.client.calls.filter(call => call.state === 'ringing' && call.incoming);
		},

		/**
		 * Finds all active calls for the user.
		 *
		 * @return {Array} The filtered array of active calls.
		 */
		activeCalls()
		{
			return this.$root.client.calls.filter(call => {
				return ['connected', 'disconnected', 'ended'].includes(call.state) || call.from.id === this.$root.client.account.id;
			});
		},

		/**
		 * Whether the current client user is an agent.
		 *
		 * @return {boolean} Whether the current user is an agent.
		 */
		isAgent()
		{
			return this.$root.client.account && this.$root.client.account.type === 'agent';
		},

		/**
		 * All the calls that the client is aware of.
		 */
		calls()
		{
			if (!this.$root.client.calls)
				return [];

			return this.$root.client.calls;
		},

		/**
		 * Get the number of the currently visible tab.
		 *
		 * @return {Number} Returns The call ID or -1 for the call list.
		 */
		activeTab()
		{
			return this.$root.activeTab;
		}
	},

	mounted()
	{
		this.interval = setInterval(this.updateTime, 1000);
	},

	beforeDestroy()
	{
		clearInterval(this.interval)
	},

	methods: {

		/**
		 * Set the active tab.
		 *
		 * @param {String} tabId The call ID, or -1 for the call list.
		 */
		changeTab(tabId)
		{
			// Ignore attempts to change to a ringing call.
			if (!this.activeCalls.map(call => call.id).includes(tabId))
				tabId = -1;

			this.$root.activeTab = tabId;
		},

		/**
		 * Close button handler
		 */
		close()
		{
			if (!this.isAgent)
			{
				for (const call of this.$root.client.calls)
					call.close();
			}

			this.minimized = true;
		},

		/**
		 * Update the current time.  Called every second on a timer.
		 */
		updateTime()
		{
			this.now = new Date();
		},

		/**
		 * Claims a specified call and changes to the appropriate tab.
		 *
		 * @param call The specified call.
		 */
		claimCall(call)
		{
			this.minimized = false;
			call.answer();
		},

		/**
		 * Event handler for starting a call from an external script.
		 *
		 * Usage:
		 *
		 * const liveChatApp = document.getElementsByClassName('live-chat-app')[0];
		 * const event = new CustomEvent('create-call');
		 * event.question = 'First chat message...';
		 * liveChatApp.dispatchEvent(event);
		 *
		 * @param {Event} event The DOM event.
		 */
		createCall(event)
		{
			this.minimized = false;
			this.$root.client.createCall(null, event.question, event.detail);
		},

		/**
		 * Event handler for showing the live chat question prompt
		 *
		 * Any extra details are stored in local variable and passed into the call when CreateCall.createCall() is called
		 *
		 * @param {Event} event The DOM event.
		 */
		show(event)
		{
			this.minimized = false;
			this.details = event.detail;
		}
	},

	watch: {
		calls()
		{
			const callIds = this.activeCalls.map(call => call.id);

			// If the currently active tab has been removed, change to the list.
			if (!callIds.includes(this.activeTab))
				this.changeTab(-1);
		}
	}
}
</script>

<style lang="scss">
	@import 'variables.scss';

	.live-chat-app *
	{
		box-sizing: border-box;
	}

	.chat-overlay
	{
		height: 100%;
		width: 100%;
		position: fixed;
		z-index: 1005;
		top: 0;
		left: 0;
		background-color: rgb(0,0,0);
		background-color: rgba(0,0,0, 0.9);
		overflow: hidden;
		transition: 0.5s;

		#new-chat-popup
		{
			max-width: 500px;
			margin: auto;
			position: relative;
			top: 20%;
			transform: translateY(-20%);

			.panel > .panel-heading
			{
				background-color: $hpGreen;
				color: white;
			}
		}

		.kudos-bubble
		{
			position: relative;
			display: inline;
			top: -10px;
			left: -10px;
			padding: 1px 4px;
			text-align: center;
			background: #438643;
			border-radius: 5px;
			color: white;
			font-size: 11px;
			font-weight: normal;
		}

		.negative-kudos
		{
			background: #e43535
		}
	}

	.chat
	{
		width: 300px;
		height: 500px;
		background: #e4e4e4;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		position: fixed;
		right: 20px;
		bottom: 20px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
		z-index: 100;
		max-height: calc(100% - 20px);

		&.live-chat-minimized
		{
			height: 50px;
			border-radius: 10px;
			overflow: hidden;
		}

		.header
		{
			background: $hpGreen;
			color: white;
			height: 50px;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;

			.medallion
			{
				display: none;
				width: 80px;
				height: 80px;
				border-radius: 40px;
				text-align: center;
				font-size: 20px;
				position: relative;
				top: -15px;
				float: left;
				background: $hpGreen;
				border: 3px solid white;

				div
				{
					padding-top: 15px;
				}
			}

			.title
			{
				padding: 10px 100px;
				text-align: center;
				font-weight: bold;
				font-size: 110%;
				margin-top: 3px;
			}

			.close
			{
				float: right;
				margin-right: 10px;
				margin-top: 15px;
				color: white;
				text-shadow: none;
				opacity: initial;
			}
		}

		.nav-tabs
		{
			overflow: visible;
			display: flex;
			flex-wrap: wrap;
			border: 0;
			margin-top: 10px;

			[class*='col-']
			{
				display: flex;
				flex-direction: column;
				padding: 0;
			}

			li
			{
				margin: 0;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				overflow: hidden;

				a
				{
					text-decoration: none;
					cursor: pointer;
					margin: 0;
					border: 0;
					border-radius: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
					background: rgba(255, 255, 255, 0.2);
				}

				i
				{
					position: absolute;
					top: 2px;
					right: 4px;
					cursor: pointer;
					color: #666;

					&:hover
					{
						color: white;
					}
				}
			}

			li.active
			{
				a, a:hover, a:focus
				{
					background-color: $hpGreen;
					color: white;
					border: 0;
				}

				i
				{
					color: #ccc;

					&:hover
					{
						color: white;
					}
				}
			}
		}
	}

	.online-booking-banner
	{
		border: 2px solid #d12828;
		border-radius: 5px;
		padding: 1em;
		margin-bottom: 1em;
		font-weight: bold;

		i
		{
			color: red;
			margin-right: 5px;
		}
	}
</style>
