/**
 * Attempt audio playback on the given dom audio element.
 * This will return a promise that will resolve to true if audio playback was successful,
 * false if failed, or undefined if unknown.
 * @param domElement
 * @returns {Promise<void>|Promise<boolean>}
 */
export const play = domElement => {
	if (!domElement instanceof Element || domElement.tagName !== 'AUDIO')
		throw new Error('play() expects an HTML audio element');

	const playPromise = domElement.play();

	// In browsers that don’t yet support this functionality,
	// playPromise won’t be defined.
	if (playPromise === undefined)
		return Promise.resolve();

	return playPromise
	.then(() => true)
	.catch(() => {
		// Playback failed.
		// This maybe due to attempting to play audio prior to the user interacting with the page.
		// Resolving to `false` rather than throwing to prevent an uncaught error.
		return false;
	});
}
