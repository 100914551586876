var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calls call-list" },
    [
      _vm._l(_vm.$root.client.calls, function(call) {
        return _c("call-list-item", {
          key: call.id,
          attrs: { call: call },
          on: {
            "focus-call": function(id) {
              return _vm.$emit("focus-call", id)
            }
          }
        })
      }),
      _vm._v(" "),
      _vm.$root.client.calls.length === 0
        ? _c("div", { staticClass: "call-list-item" }, [
            _c("i", { staticClass: "fas fas-circle" }),
            _vm._v(" There are currently no calls.")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }